import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { useState } from 'react';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';
import styles from './DeviceBigList.module.scss';
export const DeviceBigList = ({ devices, page, sortableDevices }) => {
  const [sortTable, setSortTable] = useState(false);
  const navigate = useNavigate();

  const sortAsc = (key) => {
    sortableDevices({ field: key, descending: false });
  };
  const sortDesc = (key) => {
    sortableDevices({ field: key, descending: true });
  };

  const clickRowHandler = (id) => {
    navigate(URLS.DEVICES + '/' + id + '/details?page=' + page);
  };
  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={true}
                  direction={sortTable?.Name ? 'desc' : 'asc'}
                  onClick={() => {
                    if (sortTable?.Name) {
                      sortDesc('Name');
                      setSortTable({ ...sortTable, Name: false });
                    } else {
                      sortAsc('Name');
                      setSortTable({ ...sortTable, Name: true });
                    }
                  }}
                >
                  <b>Название</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={true}
                  direction={sortTable?.DeviceCategoryName ? 'desc' : 'asc'}
                  onClick={() => {
                    if (sortTable?.DeviceCategoryName) {
                      sortDesc('DeviceCategoryName');
                      setSortTable({ ...sortTable, DeviceCategoryName: false });
                    } else {
                      sortAsc('DeviceCategoryName');
                      setSortTable({ ...sortTable, DeviceCategoryName: true });
                    }
                  }}
                >
                  <b>Категория</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <b>Код</b>
              </TableCell>
              <TableCell align="left">
                <b>Комментарий</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices?.map((device) => (
              <TableRow
                key={device?.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => clickRowHandler(device?.id)}
                className={styles?.DeviceBigList__Row}
              >
                <TableCell component="th" scope="row" sx={{ padding: '15px' }}>
                  {device?.name}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  {device?.deviceCategoryName}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  {device?.code}
                </TableCell>
                <TableCell align="left" sx={{ padding: '15px' }}>
                  {device?.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
