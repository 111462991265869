import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

export const RoleInfo = ({ role }) => {
  return (
    <Stack direction="row" spacing={5}>
      <Stack>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Создатель</div>
            <div>
              {(role?.attributes?.CreatedBy &&
                role?.attributes?.CreatedBy[0]) ||
                '-'}
            </div>
          </Stack>
        </Typography>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Редактор</div>
            <div>
              {(role?.attributes?.UpdatedBy &&
                role?.attributes?.UpdatedBy[0]) ||
                '-'}
            </div>
          </Stack>
        </Typography>
      </Stack>
      <Stack>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Создано</div>
            <div>
              {(role?.attributes?.CreateDate &&
                format(
                  new Date(role?.attributes?.CreateDate[0]),
                  'dd.MM.yyyy HH:mm:ss',
                )) ||
                '-'}
            </div>
          </Stack>
        </Typography>
        <Typography>
          <Stack direction="row" spacing={2}>
            <div style={{ opacity: '0.6', width: '100px' }}>Обновлено</div>
            <div>
              {role?.attributes?.updateDate
                ? role?.attributes?.IsReadOnly != 'True'
                  ? format(
                      new Date(role?.attributes?.updateDate[0]),
                      'dd.MM.yyyy HH:mm:ss',
                    )
                  : '-'
                : role?.attributes?.UpdateDate
                ? role?.attributes?.IsReadOnly != 'True'
                  ? format(
                      new Date(role?.attributes?.UpdateDate[0]),
                      'dd.MM.yyyy HH:mm:ss',
                    )
                  : '-'
                : '-'}
            </div>
          </Stack>
        </Typography>
      </Stack>
    </Stack>
  );
};
