import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { getOneDevice } from '../../api/DeviceApi';
import { DeviceForm } from '../DeviceForm/DeviceForm';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { useApi } from '../../hooks/useApi';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';

export const SingleDevice = ({ id, permission }) => {
  const getOneDeviceApi = useApi(getOneDevice);
  const [isCreate, setIsCreate] = useState(false);
  const [device, setDevice] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (id !== 'create') {
      getOneDeviceApi
        .sendRequest(id, [
          'parametersCategories',
          'parametersCategories.parameters',
          'deviceCategory',
        ])
        .then((device) => setDevice(device))
        .catch((error) =>
          dispatch(setAlert([{ type: 'error', text: error.message }])),
        );
    }
  }, [id]);

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.DEVICES}>
      Список типов оборудования
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новый тип оборудования' : device.name}
    </Typography>,
  ];
  return (
    (id || isCreate) && (
      <FullPageLayout
        obj={device}
        seoTitle={
          'Тип оборудования - ' + (device?.name || 'Добавить тип оборудования')
        }
        pageTitle={
          isCreate ? 'Добавить тип оборудования' : device?.name || 'Без имени'
        }
        isCreated={true}
        breadcrumbs={breadcrumbs}
      >
        <Stack direction="row" spacing={2} sx={{ mb: 3, mt: 4 }}>
          <Button
            variant={'outlined'}
            color="primary"
            onClick={() =>
              navigate(URLS.DEVICES + '/' + device.id + '/details')
            }
          >
            Основная информация
          </Button>
          <Button
            variant={'text'}
            color="primary"
            onClick={() =>
              navigate(URLS.DEVICES + '/' + device.id + '/revisions')
            }
            disabled={!device?.id}
          >
            Настройка ревизий и версий
          </Button>
        </Stack>
        <DeviceForm
          setIsCreate={(data) => setIsCreate(data)}
          isCreate={isCreate}
          device={device}
          key="1"
          permission={permission}
        />
      </FullPageLayout>
    )
  );
};
