import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { URLS } from './utils/urls.util';
import { NotFoundPage } from './pages/NotFoundPage';
import { DashboardPage } from './pages/DashboardPage';
import { MainPage } from './pages/MainPage';
import { CompaniesPage } from './pages/CompaniesPage';
import { UsersPage } from './pages/UsersPage';
import { DevicesPage } from './pages/DevicesPage';

import { FaqCategoriesPage } from './pages/FaqCategoriesPage';
import { DefaultTariffPage } from './pages/DefaultTariffPage';
import { SingleCompanyPage } from './pages/SingleCompanyPage';
import { SingleEmployeePage } from './pages/SingleEmployeePage';
import { KeyWriterPage } from './pages/KeyWriterPage';
import { ThemeProvider, createTheme } from '@mui/material';
import { ruRU as dataGridRuRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ruRU } from '@mui/x-date-pickers/locales';
import { SingleDevicePage } from './pages/SingleDevicePage';
import { SingleUserPage } from './pages/SingleUserPage';
import { SingleFaqPage } from './pages/SingleFaqPage';
import { SingleFaqCategoryPage } from './pages/SingleFaqCategoryPage';
import { SingleInstructionPage } from './pages/SingleInstructionPage';
import { SingleDeviceRevisionsPage } from './pages/SingleDeviceRevisionsPage';
import { SingleRevisionPage } from './pages/SingleRevisionPage';
import { SingleCompanyRatesPage } from './pages/SingleCompanyRatesPage';
import { SingleCompanyEmployeesPage } from './pages/SingleCompanyEmployeesPage';
import { SingleCompanyDetailsPage } from './pages/SingleCompanyDetailsPage';
import { VideoServersBasePage } from './pages/VideoServersBasePage';
import { VideoServersRTSPPage } from './pages/VideoServersRTSPPage';
import { VideoServersPage } from './pages/VideoServersPage';
import { SingleVideoServersPage } from './pages/SingleVideoServersPage';
import { SingleVideoServersRTSPPage } from './pages/SingleVideoServersRTSPPage';
import { RolesPage } from './pages/RolesPage';
import { SingleRoleUsersPage } from './pages/SingleRoleUsersPage';
import { SingleRolePage } from './pages/SingleRolePage';
import { FaqPageV2 } from './pages/FaqPageV2';
import { InstructionPageV2 } from './pages/InstructionPageV2';
import { SingleCompanySmsPage } from './pages/SingleCompanySmsPage';

const theme = createTheme(
  {
    palette: {
      grey: {
        light: '#F4F4F4',
        dark: '#F4F4F4',
        main: '#F4F4F4',
        contrastText: '#000',
      },
    },
  },
  ruRU,
  dataGridRuRU,
  coreRuRU,
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route exact path={URLS.DASHBOARD} element={<DashboardPage />} />
          <Route exact path={URLS.COMPANIES} element={<CompaniesPage />} />
          <Route
            exact
            path={URLS.EMPLOYEE_PAGE}
            element={<SingleEmployeePage />}
          />
          <Route
            exact
            path={URLS.COMPANY_PAGE}
            element={<SingleCompanyPage />}
          />
          <Route
            exact
            path={URLS.COMPANY_RATES_PAGE}
            element={<SingleCompanyRatesPage />}
          />
          <Route
            exact
            path={URLS.COMPANY_EMPLOYEES_PAGE}
            element={<SingleCompanyEmployeesPage />}
          />
          <Route
            exact
            path={URLS.COMPANY_SMS_PAGE}
            element={<SingleCompanySmsPage />}
          />
          <Route
            exact
            path={URLS.COMPANY_DETAILS_PAGE}
            element={<SingleCompanyDetailsPage />}
          />
          <Route exact path={URLS.USERS} element={<UsersPage />} />
          <Route exact path={URLS.DEVICES} element={<DevicesPage />} />
          <Route
            exact
            path={URLS.DEVICE_PAGE_REVISIONS}
            element={<SingleDeviceRevisionsPage />}
          />
          <Route
            exact
            path={URLS.DEVICE_PAGE_REVISION_SINGLE}
            element={<SingleRevisionPage />}
          />
          <Route exact path={URLS.USER_PAGE} element={<SingleUserPage />} />
          <Route exact path={URLS.DEVICE_PAGE} element={<SingleDevicePage />} />
          <Route
            exact
            path={URLS.DEVICE_PAGE_REVISIONS}
            element={<SingleDeviceRevisionsPage />}
          />
          <Route
            exact
            path={URLS.DEVICE_PAGE_REVISION_SINGLE}
            element={<SingleRevisionPage />}
          />
          <Route exact path={URLS.MAIN} element={<MainPage />} />
          <Route exact path={URLS.SETTINGS} element={<DashboardPage />} />
          <Route exact path={URLS.FAQ} element={<FaqPageV2 />} />
          <Route exact path={URLS.FAQ_SINGLE} element={<SingleFaqPage />} />
          <Route
            exact
            path={URLS.FAQ_CATEGORIES}
            element={<FaqCategoriesPage />}
          />
          <Route
            exact
            path={URLS.FAQ_CATEGORIES_SINGLE}
            element={<SingleFaqCategoryPage />}
          />
          <Route
            exact
            path={URLS.INSTRUCTION}
            element={<InstructionPageV2 />}
          />
          <Route
            exact
            path={URLS.INSTRUCTION_SINGLE}
            element={<SingleInstructionPage />}
          />

          <Route
            exact
            path={URLS.DEFAULT_TARIFF}
            element={<DefaultTariffPage />}
          />
          <Route exact path={URLS.KEY_WRITER} element={<KeyWriterPage />} />
          <Route
            exact
            path={URLS.VIDEO_SERVERS_BASE}
            element={<VideoServersBasePage />}
          />
          <Route
            exact
            path={URLS.VIDEO_SERVERS_RTSP}
            element={<VideoServersRTSPPage />}
          />
          <Route
            exact
            path={URLS.VIDEO_SERVERS}
            element={<VideoServersPage />}
          />
          <Route
            exact
            path={URLS.VIDEO_SERVERS_RTSP_PAGE}
            element={<SingleVideoServersRTSPPage />}
          />
          <Route
            exact
            path={URLS.VIDEO_SERVERS_PAGE}
            element={<SingleVideoServersPage />}
          />
          <Route exact path={URLS.ROLES} element={<RolesPage />} />
          <Route
            exact
            path={URLS.ROLE_SINGLE_PAGE}
            element={<SingleRolePage />}
          />
          <Route
            exact
            path={URLS.ROLE_SINGLE_USERS_PAGE}
            element={<SingleRoleUsersPage />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
