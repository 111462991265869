import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { BigNavigationItem } from '../BigNavigationItem/BigNavigationItem';
import CompaniesImg from '../../assets/Images/companies.jpg';
import AdminsImg from '../../assets/Images/admins.jpg';
import RolesImg from '../../assets/Images/roles.jpg';
import DevicesImg from '../../assets/Images/devices.jpg';

import FaqImg from '../../assets/Images/faq.jpg';
import InstructionImg from '../../assets/Images/instruction.jpg';
import RatesMPImg from '../../assets/Images/ratesmp.jpg';
import CamSettingsImg from '../../assets/Images/video-servers.jpg';
import KeyWriterImg from '../../assets/Images/key-writer.jpg';
import { URLS } from '../../utils/urls.util';
import { pagesIds } from '../../utils/pages';

import { useApi } from '../../hooks/useApi';

import { getCounts } from '../../api/AppSettings';

export const BigNavigation = ({ adminPermissions = [] }) => {
  const getCountsApi = useApi(getCounts);

  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    if (adminPermissions?.length)
      getCountsApi
        .sendRequest()
        .then((counts) =>
          setNavigation([
            {
              id: pagesIds.company,
              url: URLS.COMPANIES,
              title: 'Организации',
              img: CompaniesImg,
              count: counts.companies,
              showCount: true,
            },
            {
              id: pagesIds.devices,
              url: URLS.DEVICES,
              title: 'Типы оборудования',
              img: DevicesImg,
              count: counts.devices,
              showCount: true,
            },
            {
              id: pagesIds.users,
              url: URLS.USERS,
              title: 'Пользователи',
              img: AdminsImg,
              count: counts.admins,
              showCount: true,
            },
            {
              id: pagesIds.roles,
              url: URLS.ROLES,
              title: 'Роли',
              img: RolesImg,
              count: counts?.roles,
              showCount: true,
            },

            {
              id: pagesIds.faq,
              url: URLS.FAQ,
              title: 'Частые вопросы (FAQ)',
              img: FaqImg,
              count: counts.faq,
              showCount: true,
            },
            {
              id: pagesIds.instructions,
              url: URLS.INSTRUCTION,
              title: 'Инструкции',
              img: InstructionImg,
              count: counts.instruction,
              showCount: true,
            },
            {
              id: pagesIds.tariff,
              url: URLS.DEFAULT_TARIFF,
              title: 'Управление тарифами МП',
              img: RatesMPImg,
              count: 3,
              showCount: true,
            },
            {
              id: pagesIds.defaultCameraSettings,
              url: URLS.VIDEO_SERVERS_BASE,
              title: 'Настройки камеры',
              img: CamSettingsImg,
              showCount: false,
            },
            {
              id: pagesIds.keyWriter,
              url: URLS.KEY_WRITER,
              title: 'Утилита для работы с ключами',
              img: KeyWriterImg,
              count: counts.keyWriter,
              showCount: true,
            },
          ]),
        )
        .catch((error) => console.log(error));
  }, [adminPermissions]);

  const availableTabs = useMemo(
    () =>
      navigation.filter((nav) =>
        adminPermissions.find((item) => item.key === nav.id && item.view),
      ),
    [adminPermissions, navigation],
  );

  return (
    <Grid container spacing={2}>
      {!!availableTabs?.length
        ? availableTabs.map((nav) => (
            <Grid item md={3} key={nav.id}>
              <BigNavigationItem nav={nav} />
            </Grid>
          ))
        : [1, 2, 3, 4, 5, 6, 7, 8]?.map((i) => (
            <Grid item md={3} key={i}>
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ width: '100%' }}
                height={305}
              />
            </Grid>
          ))}
    </Grid>
  );
};
